

























































import FileUpload from "primevue/fileupload";
import { Component, PropSync, Vue } from "vue-property-decorator";
import { api } from "@/api";
import "@/assets/styles/account-dialog.css";

@Component({
  components: {
    FileUpload,
  },
})
export default class CreatorImport extends Vue {
  @PropSync("isOpen", { type: Boolean })
  isOpenSync!: boolean;

  public typeOptions = ["Individual", "Organization"];
  public creatorType = "Individual";
  public isLoading = false;
  public uploaderKey = 0;

  public get templateUrl() {
    const filename =
      this.creatorType === "Individual" ? "individual" : "organization";
    return `/api/static/templates/creators/${filename}.xlsx`;
  }

  public hide() {
    this.isOpenSync = false;
    this.$router.push("/manage/creators");
  }

  public async importFile(event) {
    this.isLoading = true;
    const file = event.files[0];
    const response = await api.importCreators(this.creatorType, file);
    if (response) {
      if (response.data.total_records_processed > 0) {
        if (response.data.skipped_records.length === 0) {
          this.addToast("success", "Your file has been successfully imported.");
          this.isOpenSync = false;
        } else {
          this.addToast(
            "warn",
            "Some rows have been skipped due to missing or invalid fields."
          );
        }
        this.$emit("import-success");
      } else {
        this.addToast(
          "error",
          "No record has been processed. Please make sure you use the correct template."
        );
      }
    } else {
      this.addToast("error", "Could not import file.");
    }
    this.uploaderKey++;
    this.isLoading = false;
  }

  private addToast(type, message) {
    this.$toast.add({
      severity: type,
      summary: message,
      life: 5000,
      group: "bl",
    });
  }

  public addEventListeners() {
    const dropzone = document.getElementsByClassName("p-fileupload-content")[0];
    dropzone.addEventListener("dragover", (event) => {
      dropzone.classList.add("dragging");
    });
    dropzone.addEventListener("dragleave", (event) => {
      dropzone.classList.remove("dragging");
    });
    dropzone.addEventListener("drop", (event) => {
      dropzone.classList.remove("dragging");
    });
  }
}
