





























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { readCountries, readCreators } from "@/store/main/getters";
import { dispatchListCreators } from "@/store/main/actions";
import CreatorImport from "./CreatorImport.vue";
import "@/assets/styles/datatable.css";

@Component({
  components: {
    CreatorImport,
  },
})
export default class Creators extends Vue {
  public yesNoOptions = [
    {
      name: "Yes",
      value: "true",
    },
    {
      name: "No",
      value: "false",
    },
  ];

  public columns = [
    {
      header: "ID",
      field: "id",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header data-table-header-short",
      filterClass: "p-column-filter p-filter-column p-filter-column-first",
    },
    {
      header: "First Name",
      field: "first_name",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Last Name",
      field: "last_name",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Email",
      field: "email",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Phone Number",
      field: "phone_number",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-column-filter p-filter-column",
    },
    {
      header: "Country",
      field: "residence_country",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header data-table-header-long",
      filterClass: "p-filter-dropdown",
    },
    {
      header: "Termination Scheduled",
      field: "termination_scheduled",
      sortable: true,
      bodyClass: "data-table-body data-table-body-dense",
      headerClass: "data-table-header",
      filterClass: "p-filter-dropdown",
    },
  ];

  public filterConfig = {
    id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    first_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    last_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    email: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    phone_number: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    residence_country: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    termination_scheduled: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  };

  public showImportModal = false;
  public filters = {};
  public filteredCreators = [];
  public isLoading = false;

  public get countries() {
    return readCountries(this.$store);
  }

  public get countryCodeMapping() {
    const mapping = {};
    this.countries.forEach((country) => {
      mapping[country.alpha2Code] = country.name;
    });
    return mapping;
  }

  public get creators() {
    return readCreators(this.$store);
  }

  public filterCreators() {
    let out = this.creators;
    for (const [key, value] of Object.entries(this.filters)) {
      if (key == "residence_country" || key == "termination_scheduled") {
        if (value !== null && value !== 0 && value !== "") {
          out = out.filter(
            (creator) => (creator[key] ?? "").toString() == value
          );
        }
      } else if (value !== null && value !== "") {
        out = out.filter((creator) => {
          if (creator[key] !== null && creator[key] !== "") {
            return (creator[key] ?? "")
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase());
          }
          return false;
        });
      }
    }
    this.filteredCreators = out;
  }

  public async refreshCreatorList() {
    this.isLoading = true;
    await dispatchListCreators(this.$store);
    this.filteredCreators = this.creators;
    this.isLoading = false;
  }

  @Watch("$route.path", { immediate: true })
  public toggleCreatorModal() {
    if (this.$route.path === "/manage/creators/import") {
      this.showImportModal = true;
    }
  }

  public async mounted() {
    window["analytics"]["page"]("/manage/creators");

    this.isLoading = true;
    await dispatchListCreators(this.$store);
    this.filteredCreators = this.creators;
    this.isLoading = false;
  }
}
